<template>
	<div class="section-1">
		<div class="container-section-mobile">
			<div class="title-mobile">TBF Digital<br/>Simplifică Managementul</div>
			<div class="image-animated-mobile"><svg-section-1 /></div>
			<div class="btn-action-mobile">
				<button class="try-free-btn" @click="openPricing">Vezi abonamentele disponibile <icon-arrow class="icon-arrow right"/></button>
			</div>
		</div>
	</div>
</template>

<script>
	import IconArrow from '../../assets/landing/Arrow'
	import SvgSection1 from '../../assets/landing/mobile/Svg01Section01'


	export default {
		components: {
			IconArrow,
			SvgSection1
		},
		methods: {
			openPricing(){
				window.open(this.$router.resolve({name: 'pricing'}).href, '_blank')
			}
		}
	}
</script>